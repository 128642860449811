import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './styles.css'; // Import shared styles

const ThirdPage = () => {
  const { group } = useParams();

  return (
    <div className="thirdPageContainer">
      <div className="backButton">
        <Link to={`/${group}`} className="backLink">
          <ArrowBackIosIcon /> Back
        </Link>
      </div>
      <div className="thirdPageContent">
        {/* Replace with your third page content */}
      </div>
    </div>
  );
}

export default ThirdPage;
