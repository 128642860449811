import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'; // Import ArrowBackIosIcon
import './styles.css'; // Import shared styles

const SubMenu = () => {
  const { group } = useParams();

  const categoryGroups = {
    'Home Services': ['handyman', 'aircon', 'electrician', 'plumber', 'repair', 'movers', 'helper', 'pestcontrol', 'transport', 'locksmith', 'carpentry', 'renovation'],
    'Food': ['alacarte', 'catering', 'bakery'],
    'Professional Services': ['medical'],
    'Travel': ['travel', 'attractions'],
    'Learning': ['learning'],
    'Miscellaneous': ['misc', 'links']
  };

  return (
    <div className="submenuContainer">
      <div className="backButton">
        <Link to="/" className="backLink">
          <ArrowBackIosIcon /> Back
        </Link>
      </div>
      <div className="categoryTiles">
        {categoryGroups[group].map(category => (
          <Link key={category} to={`/${group}/${category}`} className="categoryTile">
            {category}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SubMenu;
