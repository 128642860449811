import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './styles.css'; // Import shared styles

const GET_DATA = gql`
  query GetHelpBotData($category: String!) {
    help_bot_data(
      limit: 10,
      offset: 0,
      order_by: { modified_ts: asc },
      where: { category: { _eq: $category }, status: { _eq: "LIVE" } }
    ) {
      help_bot_id
      message
    }
  }
`;

const CategoryData = () => {
  const { group, category } = useParams();
  const { loading, error, data } = useQuery(GET_DATA, {
    variables: { category }
  });

  const makeLinksClickable = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const phoneRegex = /(\+?[0-9]{10,15})/g;

    return text.replace(urlRegex, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>')
      .replace(phoneRegex, '<a href="tel:$1">$1</a>');
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="categoryDataContainer">
      <div className="backButton">
        <Link to={`/${group}`} className="backLink">
          <ArrowBackIosIcon /> Back
        </Link>
      </div>
      {data.help_bot_data.map(item => (
        <div key={item.help_bot_id} className="categoryMessage">
          <span dangerouslySetInnerHTML={{ __html: makeLinksClickable(item.message) }} />
        </div>
      ))}
    </div>
  );
}

export default CategoryData;
