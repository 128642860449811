import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css'; // Import shared styles

const Menu = () => {
  const categoryGroups = {
    'Home Services': ['handyman', 'aircon', 'electrician', 'plumber', 'repair', 'movers', 'helper', 'pestcontrol', 'transport', 'locksmith', 'carpentry', 'renovation'],
    'Food': ['alacarte', 'catering', 'bakery'],
    'Professional Services': ['medical'],
    'Travel': ['travel', 'attractions'],
    'Learning': ['learning'],
    'Miscellaneous': ['misc', 'links']
  };

  return (
    <div className="menuContainer">
      {Object.keys(categoryGroups).map(group => (
        <Link key={group} to={`/${group}`} className="tile">
          {group}
        </Link>
      ))}
    </div>
  );
}

export default Menu;
    