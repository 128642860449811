import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import Menu from './Menu';
import SubMenu from './SubMenu';
import CategoryData from './CategoryData';
import ThirdPage from './ThirdPage';
import BottomNavBar from './BottomNavBar';
import './styles.css';

const client = new ApolloClient({
  uri: 'https://findrr.ddns.net/v1/graphql',
  cache: new InMemoryCache()
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route path="/" element={<Menu />} />
          <Route path="/:group" element={<SubMenu />} />
          <Route path="/:group/:category" element={<CategoryData />} />
          <Route path="/third-page" element={<ThirdPage />} />
        </Routes>
        <BottomNavBar />
      </Router>
    </ApolloProvider>
  );
}

export default App;
